import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import { getMenuByRole } from '../helpers/api';

// lazy load all the views

// useComponent
const useComponent = React.lazy(() => import('../components/useComponent/UseComponent'));
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));

// dashboard
const dashboard = React.lazy(() => import('../pages/Dashboard'));

//hospital
const hospital = React.lazy(() => import('../pages/hospital/HospitalManagement'));

//patient
const patient = React.lazy(() => import('../pages/patient/PatientManagement'));

//user
const user = React.lazy(() => import('../pages/user/UserManagement'));

//registAnalysis
const registAnalysis = React.lazy(() => import('../pages/analysis/RegistAnalysisManagement'));

//analysis
const analysis = React.lazy(() => import('../pages/analysis/AnalysisManagement'));

//content
const content = React.lazy(() => import('../pages/content/ContentManagement'));

//product
const product = React.lazy(() => import('../pages/product/ProductManagement'));

//commonCode
const commonCode = React.lazy(() => import('../pages/commonCode/CommonCodeManagement'));

//authority
const authority = React.lazy(() => import('../pages/authority/AuthorityManagement'));

//commonCodeDetail
const commonCodeDetail = React.lazy(() => import('../pages/commonCode/CommonCodeDetailManagement'));

//ResponseAnalysisManagement
const responseAnalysis = React.lazy(() => import('../pages/analysis/ResponseAnalysisManagement'));

//responseAnalysisMoileManagement
const responseMobileAnalysis = React.lazy(() =>
    import('../pages/analysis/ResponseMobileAnalysisManagement')
);

//examination
const examination = React.lazy(() => import('../pages/examination/ExaminationManagement'));

//treatment
const treatment = React.lazy(() => import('../pages/treatment/TreatmentManagement'));

//crm
const crm = React.lazy(() => import('../pages/crm/CrmManagement'));

//performance
const performance = React.lazy(() => import('../pages/performance/PerformanceManagement'));

//myPage
const myPage = React.lazy(() => import('../pages/myPage/MyPageManagement'));

//examinationResult
const examinationResult = React.lazy(() =>
    import('../pages/examination/ExaminationResultManagement')
);

//teethAgeInfo
const teethAgeInfo = React.lazy(() => import('../pages/teethAgeInfo/teethAgeInfoManagement'));

//aiobio web mobile - userInfo
const userInfoPage = React.lazy(() => import('../pages/aiobioWebMobile/UserInfo'));

const registAnlyStep1Page = React.lazy(() => import('../pages/aiobioWebMobile/RegistAnlyStep1'));

const registAnlyStep2Page = React.lazy(() => import('../pages/aiobioWebMobile/RegistAnlyStep2'));

const registAnlyListPage = React.lazy(() => import('../pages/aiobioWebMobile/RegistAnlyList'));

const loginPage = React.lazy(() => import('../pages/aiobioWebMobile/Login'));

const responseAnlyListPage = React.lazy(() => import('../pages/aiobioWebMobile/ResponseAnlyList'));

const responseAnlyDetailPage = React.lazy(() =>
    import('../pages/aiobioWebMobile/ResponseAnlyDetail')
);

const NotFoundPage = React.lazy(() => import('../pages/error/PageNotFound'))

// apps
const CalendarApp = React.lazy(() => import('../pages/apps/Calendar'));
const Projects = React.lazy(() => import('../pages/apps/Projects/'));
const ProjectDetail = React.lazy(() => import('../pages/apps/Projects/Detail/'));
const ProjectGannt = React.lazy(() => import('../pages/apps/Projects/Gantt/'));
const ProjectForm = React.lazy(() => import('../pages/apps/Projects/ProjectForm'));
// - ecommece pages
const EcommerceProducts = React.lazy(() => import('../pages/apps/Ecommerce/Products'));
const ProductDetails = React.lazy(() => import('../pages/apps/Ecommerce/ProductDetails'));
const Orders = React.lazy(() => import('../pages/apps/Ecommerce/Orders'));
const OrderDetails = React.lazy(() => import('../pages/apps/Ecommerce/OrderDetails'));
const Customers = React.lazy(() => import('../pages/apps/Ecommerce/Customers'));
const Cart = React.lazy(() => import('../pages/apps/Ecommerce/Cart'));
const Checkout = React.lazy(() => import('../pages/apps/Ecommerce/Checkout/'));
const Sellers = React.lazy(() => import('../pages/apps/Ecommerce/Sellers'));
// chat
const ChatApp = React.lazy(() => import('../pages/apps/Chat/'));
// social
const SocialFeed = React.lazy(() => import('../pages/apps/SocialFeed/'));
// tasks
const TaskList = React.lazy(() => import('../pages/apps/Tasks/List/'));
const Kanban = React.lazy(() => import('../pages/apps/Tasks/Board/'));

// - email
const Inbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));

// pages
const Starter = React.lazy(() => import('../pages/Starter'));
const Profile = React.lazy(() => import('../pages/profile'));
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));

// - other
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const FAQ = React.lazy(() => import('../pages/other/FAQ'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Timeline = React.lazy(() => import('../pages/other/Timeline'));

// uikit
const Accordions = React.lazy(() => import('../pages/uikit/Accordions'));
const Alerts = React.lazy(() => import('../pages/uikit/Alerts'));
const Badges = React.lazy(() => import('../pages/uikit/Badges'));
const Buttons = React.lazy(() => import('../pages/uikit/Buttons'));
const Cards = React.lazy(() => import('../pages/uikit/Cards'));
const Carousel = React.lazy(() => import('../pages/uikit/Carousel'));
const Dropdowns = React.lazy(() => import('../pages/uikit/Dropdowns'));
const ListGroups = React.lazy(() => import('../pages/uikit/ListGroups'));
const Modals = React.lazy(() => import('../pages/uikit/Modals'));
const Tabs = React.lazy(() => import('../pages/uikit/Tabs'));
const Toasts = React.lazy(() => import('../pages/uikit/Toasts'));
const Grid = React.lazy(() => import('../pages/uikit/Grid'));
const Popovers = React.lazy(() => import('../pages/uikit/Popovers'));
const Progress = React.lazy(() => import('../pages/uikit/Progress'));
const Ribbons = React.lazy(() => import('../pages/uikit/Ribbons'));
const Tooltips = React.lazy(() => import('../pages/uikit/Tooltips'));
const Typography = React.lazy(() => import('../pages/uikit/Typography'));
const Spinners = React.lazy(() => import('../pages/uikit/Spinners'));
const Widgets = React.lazy(() => import('../pages/uikit/Widgets'));
const DragDrop = React.lazy(() => import('../pages/uikit/DragDrop'));
const RangeSliders = React.lazy(() => import('../pages/uikit/RangeSliders'));
const Ratings = React.lazy(() => import('../pages/uikit/Ratings'));

const MDIIcons = React.lazy(() => import('../pages/uikit/MDIIcons'));
const Dripicons = React.lazy(() => import('../pages/uikit/Dripicons'));
const Unicons = React.lazy(() => import('../pages/uikit/Unicons'));
// forms
const BasicForms = React.lazy(() => import('../pages/forms/Basic'));
const FormValidation = React.lazy(() => import('../pages/forms/Validation'));
const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
const Editors = React.lazy(() => import('../pages/forms/Editors'));
// charts
const ApexChart = React.lazy(() => import('../pages/charts/Apex'));
const BriteChart = React.lazy(() => import('../pages/charts/Brite'));
const ChartJs = React.lazy(() => import('../pages/charts/ChartJs'));
// tables
const BasicTables = React.lazy(() => import('../pages/tables/Basic'));
const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'));
// maps
const GoogleMaps = React.lazy(() => import('../pages/GoogleMaps'));

// handle auth and authorization

const adminRoutes = ['dashboard', 'hospital', 'patient', 'user','analysis', 'product','authority', 'registAnalysis', 'responseAnalysis'];
const hsptRoutes = [ 'dashboard','patient', 'analysis', 'user', 'product', 'registAnalysis', 'responseAnalysis'];
const anlyRoutes = ['dashboard','analysis', 'registAnalysis', 'responseAnalysis']
const etcRoutes = ['dashboard','patient','analysis','registAnalysis', 'responseAnalysis']

const useRoutes = {
    ROLE_ADMIN: adminRoutes,
    ROLE_HSPT: etcRoutes,
    ROLE_DNTS: etcRoutes,
    ROLE_ANLY: anlyRoutes,
    ROLE_HSMIN: hsptRoutes
}

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return window.location.replace('/account/login');
            }

            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser.role) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }

            const P = rest.path.replace('/', '')
            const R = loggedInUser.roles[0];
            // 권한별 들어갈 수 있는 route 에만 들어갈 수 있도록
            if(!!P && !!R && useRoutes[R] && useRoutes[R].includes(P)){
                return <Component {...props} />;
            }

            return <Redirect to={{ pathname: '/' }} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: Route,
};

// dashboards
const dashboardRoutes = {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'uil-home-alt',
    component: dashboard,
    route: PrivateRoute,
};

//hsopitalTest
const hospitalRoutes = {
    path: '/hospital',
    name: 'Hospital',
    component: hospital,
    icon: 'uil-medical-square',
    route: PrivateRoute,
};

//patinet
const patientRoutes = {
    path: '/patient',
    name: 'Patient',
    component: patient,
    icon: 'dripicons-user-id',
    route: PrivateRoute,
};

const userInfo = {
    path: '/useRInfo',
    name: 'UserInfo',
    component: userInfoPage,
    icon: 'dripicons-user-id',
    route: PrivateRoute,
};

const registAnlyStep1 = {
    path: '/registAnlyStep1',
    name: 'registAnlyStep1',
    component: registAnlyStep1Page,
    icon: 'dripicons-user-id',
    route: PrivateRoute,
};

const registAnlyStep2 = {
    path: '/registAnlyStep2',
    name: '/registAnlyStep2',
    component: registAnlyStep2Page,
    icon: 'dripicons-user-id',
    route: PrivateRoute,
};

const registAnlyList = {
    path: '/registAnlyList',
    name: '/registAnlyList',
    component: registAnlyListPage,
    icon: 'dripicons-user-id',
    route: PrivateRoute,
};

/*const mobileLogin = {
    path: '/mobileLogin',
    name: 'mobileLogin',
    component: loginPage,
    icon: 'dripicons-user-id',
    route: PrivateRoute,
}*/

const responseAnlyList = {
    path: '/responseAnlyList',
    name: 'responseAnlyList',
    component: responseAnlyListPage,
    icon: 'dripicons-user-id',
    route: PrivateRoute,
};

const responseAnlyDetail = {
    path: '/responseAnlyDetail',
    name: 'responseAnlyDetail',
    component: responseAnlyDetailPage,
    icon: 'dripicons-user-id',
    route: PrivateRoute,
};
//user
const userRoutes = {
    path: '/user',
    name: 'User',
    component: user,
    icon: 'uil-user',
    route: PrivateRoute,
};

//registAnalysis
const registAnalysisRoutes = {
    path: '/registAnalysis',
    name: 'Regist Analysis',
    component: registAnalysis,
    icon: 'uil-lightbulb-alt',
    route: PrivateRoute,
};

//analysis
const analysisRoutes = {
    path: '/analysis',
    name: 'Analysis Management',
    component: analysis,
    icon: 'uil-lightbulb-alt',
    route: PrivateRoute,
};

//contnet
// const contentRoutes = {
//     path: '/content',
//     name: 'Contnet',
//     component: content,
//     icon: 'uil-lightbulb-alt',
//     route: PrivateRoute,
// }

//product
const productRoutes = {
    path: '/product',
    name: 'Product',
    component: product,
    icon: 'uil-gift',
    route: PrivateRoute,
};

//examination
const examinationRoutes = {
    path: '/examination',
    name: 'Examination',
    component: examination,
    icon: 'uil-monitor-heart-rate',
    route: PrivateRoute,
};

//treat
const treatmentRoutes = {
    path: '/treatment',
    name: 'Treatment',
    component: treatment,
    icon: 'uil-medkit',
    route: PrivateRoute,
};

//crm
const crmRoutes = {
    path: '/crm',
    name: 'CRM',
    component: crm,
    icon: 'uil-puzzle-piece',
    route: PrivateRoute,
};

//performance
const performanceRoutes = {
    path: '/performance',
    name: 'performance',
    component: performance,
    icon: 'uil-medal',
    route: PrivateRoute,
};

//myPage
const myPageRoutes = {
    path: '/myPage',
    name: 'myPage',
    component: myPage,
    icon: 'uil-user-square',
    route: PrivateRoute,
};

//examinationResult
const examinationResultRoutes = {
    path: '/examinationResult',
    name: 'examinationResult',
    component: examinationResult,
    icon: 'uil-receipt-alt',
    route: PrivateRoute,
};

//teethAgeInfo
const teethAgeInfoRoutes = {
    path: '/teethAgeInfo',
    name: 'teethAgeInfo',
    component: teethAgeInfo,
    icon: 'uil-layer-group',
    route: PrivateRoute,
};

//commonCode
const commonCodeRoutes = {
    path: '/commonCode',
    name: 'Common Code',
    component: commonCode,
    icon: 'uil-bright',
    route: PrivateRoute,
};

//authority
const authorityRoutes = {
    path: '/authority',
    name: 'Authority',
    component: authority,
    icon: 'uil-shield-check',
    route: PrivateRoute,
};
// commoncodeDetail
const commonCodeDetailRoutes = {
    path: '/commonCodeDetail',
    name: 'Common Code Detail',
    component: commonCodeDetail,
    icon: 'uil-heart',
    route: PrivateRoute,
};
const componentRoutes = {
    path: '/useComponent',
    name: 'UseComponent',
    component: useComponent,
    icon: 'uil-home-alt',
    route: PrivateRoute,
};
const calendarAppRoutes = {
    path: '/apps/calendar',
    name: 'Calendar',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-calender',
    component: CalendarApp,
    header: 'Apps',
};

const chatAppRoutes = {
    path: '/apps/chat',
    name: 'Chat',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-comments-alt',
    component: ChatApp,
};

const ecommerceAppRoutes = {
    path: '/apps/ecommerce',
    name: 'eCommerce',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-store',
    children: [
        {
            path: '/apps/ecommerce/products',
            name: 'Products',
            component: EcommerceProducts,
            route: PrivateRoute,
        },
        {
            path: '/apps/ecommerce/details',
            name: 'Product Details',
            component: ProductDetails,
            route: PrivateRoute,
        },
        {
            path: '/apps/ecommerce/orders',
            name: 'Orders',
            component: Orders,
            route: PrivateRoute,
        },
        {
            path: '/apps/ecommerce/order/details',
            name: 'Order Details',
            component: OrderDetails,
            route: PrivateRoute,
        },
        {
            path: '/apps/ecommerce/customers',
            name: 'Customers',
            component: Customers,
            route: PrivateRoute,
        },
        {
            path: '/apps/ecommerce/shopping-cart',
            name: 'Shopping Cart',
            component: Cart,
            route: PrivateRoute,
        },
        {
            path: '/apps/ecommerce/checkout',
            name: 'Checkout',
            component: Checkout,
            route: PrivateRoute,
        },
        {
            path: '/apps/ecommerce/sellers',
            name: 'Sellers',
            component: Sellers,
            route: PrivateRoute,
        },
    ],
};

const emailAppRoutes = {
    path: '/apps/email',
    name: 'Email',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-envelope',
    children: [
        {
            path: '/apps/email/inbox',
            name: 'Inbox',
            component: Inbox,
            route: PrivateRoute,
        },
        {
            path: '/apps/email/details',
            name: 'Email Details',
            component: EmailDetail,
            route: PrivateRoute,
        },
    ],
};

const projectAppRoutes = {
    path: '/apps/projects',
    name: 'Projects',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-briefcase',

    children: [
        {
            path: '/apps/projects/list',
            name: 'List',
            component: Projects,
            route: PrivateRoute,
        },
        {
            path: '/apps/projects/:id/details',
            name: 'Detail',
            component: ProjectDetail,
            route: PrivateRoute,
        },
        {
            path: '/apps/projects/gantt',
            name: 'Gantt',
            component: ProjectGannt,
            route: PrivateRoute,
        },
        {
            path: '/apps/projects/new',
            name: 'Create Project',
            component: ProjectForm,
            route: PrivateRoute,
        },
    ],
};

const socialAppRoutes = {
    path: '/apps/social',
    name: 'Social Feed',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-rss',
    component: SocialFeed,
};

const taskAppRoutes = {
    path: '/apps/tasks',
    name: 'Tasks',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-clipboard-alt',
    children: [
        {
            path: '/apps/tasks/list',
            name: 'Task List',
            component: TaskList,
            route: PrivateRoute,
        },
        {
            path: '/apps/tasks/kanban',
            name: 'Kanban',
            component: Kanban,
            route: PrivateRoute,
        },
    ],
};

//responseAnalysis
const responseAnalysisRoutes = {
    path: '/responseAnalysis',
    name: 'Response Analysis',
    component: responseAnalysis,
    icon: 'uil-heart',
    route: PrivateRoute,
};

//responseAnalysisMobile
const responseMobileAnalysisRoutes = {
    path: '/responseMobileAnalysis',
    name: 'Response Mobile Analysis',
    component: responseMobileAnalysis,
    icon: 'uil-heart',
    route: PrivateRoute,
};
const appRoutes = [
    //chatAppRoutes,
    //ecommerceAppRoutes,
    //emailAppRoutes,
    //projectAppRoutes,
    //socialAppRoutes,
    //taskAppRoutes,
];

// pages
/*const pageRoutes = {
    path: '/pages',
    name: 'Pages',
    icon: 'uil-copy-alt',
    header: 'Custom',
    children: [
        {
            path: '/pages/starter',
            name: 'Starter',
            component: Starter,
            route: PrivateRoute,
        },
        {
            path: '/pages/profile',
            name: 'Profile',
            component: Profile,
            route: PrivateRoute,
        },
        {
            path: '/pages/invoice',
            name: 'Invoice',
            component: Invoice,
            route: PrivateRoute,
        },
        {
            path: '/pages/faq',
            name: 'FAQ',
            component: FAQ,
            route: PrivateRoute,
        },
        {
            path: '/pages/pricing',
            name: 'Pricing',
            component: Pricing,
            route: PrivateRoute,
        },
        {
            path: '/pages/error-404',
            name: 'Error - 404',
            component: ErrorPageNotFound,
            route: PrivateRoute,
        },
        {
            path: '/pages/error-500',
            name: 'Error - 500',
            component: ServerError,
            route: PrivateRoute,
        },
        {
            path: '/pages/timeline',
            name: 'Timeline',
            component: Timeline,
            route: PrivateRoute,
        },
    ],
};*/

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/account/mobileLogin',
            name: 'mobileLogin',
            component: loginPage,
            route: Route,
        },
    ],
};

// ui
/*const uiRoutes = {
    path: '/ui',
    name: 'Components',
    icon: 'uil-package',
    header: 'UI Elements',
    children: [
        {
            path: '/ui/base',
            name: 'Base UI',
            children: [
                {
                    path: '/ui/accordions',
                    name: 'Accordions',
                    component: Accordions,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/alerts',
                    name: 'Alerts',
                    component: Alerts,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/badges',
                    name: 'Badges',
                    component: Badges,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/buttons',
                    name: 'Buttons',
                    component: Buttons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/cards',
                    name: 'Cards',
                    component: Cards,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/carousel',
                    name: 'Carousel',
                    component: Carousel,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/dropdowns',
                    name: 'Dropdowns',
                    component: Dropdowns,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/grid',
                    name: 'Grid',
                    component: Grid,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/listgroups',
                    name: 'List Groups',
                    component: ListGroups,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/modals',
                    name: 'Modals',
                    component: Modals,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/popovers',
                    name: 'Popovers',
                    component: Popovers,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/progress',
                    name: 'Progress',
                    component: Progress,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/ribbons',
                    name: 'Ribbons',
                    component: Ribbons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/spinners',
                    name: 'Spinners',
                    component: Spinners,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/tabs',
                    name: 'Tabs',
                    component: Tabs,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/toasts',
                    name: 'Toasts',
                    component: Toasts,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/tooltips',
                    name: 'Tooltips',
                    component: Tooltips,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/typography',
                    name: 'Typography',
                    component: Typography,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/widgets',
                    name: 'Widgets',
                    component: Widgets,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/ui/extended',
            name: 'Extended UI',
            children: [
                {
                    path: '/ui/dragdrop',
                    name: 'Drag and Drop',
                    component: DragDrop,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/rangesliders',
                    name: 'Range Sliders',
                    component: RangeSliders,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/ratings',
                    name: 'Ratings',
                    component: Ratings,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/ui/icons',
            name: 'Icons',
            children: [
                {
                    path: '/ui/icons/dripicons',
                    name: 'Dripicons',
                    component: Dripicons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/icons/mdi',
                    name: 'Material Design',
                    component: MDIIcons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/icons/unicons',
                    name: 'Unicons',
                    component: Unicons,
                    route: Unicons,
                },
            ],
        },
        {
            path: '/ui/forms',
            name: 'Forms',
            children: [
                {
                    path: '/ui/forms/basic',
                    name: 'Basic Elements',
                    component: BasicForms,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/forms/advanced',
                    name: 'Form Advanced',
                    component: FormAdvanced,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/forms/validation',
                    name: 'Form Validation',
                    component: FormValidation,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/forms/wizard',
                    name: 'Form Wizard',
                    component: FormWizard,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/forms/upload',
                    name: 'File Upload',
                    component: FileUpload,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/forms/editors',
                    name: 'Editors',
                    component: Editors,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/ui/charts',
            name: 'Charts',
            children: [
                {
                    path: '/ui/charts/apex',
                    name: 'Apex',
                    component: ApexChart,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/charts/brite',
                    name: 'Brite',
                    component: BriteChart,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/charts/chartjs',
                    name: 'Chartjs',
                    component: ChartJs,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/ui/tables',
            name: 'Tables',
            children: [
                {
                    path: '/ui/tables/basic',
                    name: 'Basic',
                    component: BasicTables,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/tables/advanced',
                    name: 'Advanced',
                    component: AdvancedTables,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/ui/googlemaps',
            name: 'Google Maps',
            component: GoogleMaps,
            route: PrivateRoute,
        },
    ],
};*/

const notFoundRoute = {
    path: '',
    name: '',
    component: () => <Redirect to="/dashboard" />,
    route: Route,
}


// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    ...appRoutes,
    dashboardRoutes,
    hospitalRoutes,
    authRoutes,
    responseAnalysisRoutes,
    responseMobileAnalysisRoutes,
    patientRoutes,
    userRoutes,
    registAnalysisRoutes,
    analysisRoutes,
    productRoutes,
    commonCodeRoutes,
    commonCodeDetailRoutes,
    componentRoutes,
    authorityRoutes,
    userInfo,
    registAnlyStep1,
    registAnlyStep2,
    registAnlyList,
    responseAnlyList,
    responseAnlyDetail,
    examinationRoutes,
    treatmentRoutes,
    crmRoutes,
    performanceRoutes,
    myPageRoutes,
    examinationResultRoutes,
    teethAgeInfoRoutes,
    notFoundRoute
];

// const authProtectedRoutes = [...appRoutes, dashboardRoutes, hospitalRoutes, patientRoutes, userRoutes, registAnalysisRoutes, analysisRoutes, productRoutes, commonCodeRoutes, authorityRoutes, componentRoutes, userInfo];
let authProtectedRoutes = [...appRoutes];

/*const roles = localStorage.getItem('userRoles');
if (roles !== null) {
    getMenuByRole({role: roles}).then(data => {
        data.forEach(menu => {
           const route = allRoutes.filter(ar => ar.path === menu.cdDscr);
           if (route !== undefined) {
               route[0].name = menu.cdName;
               authProtectedRoutes.push(route[0]);
           }
        });
    });
}*/

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
