/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */

import axios from 'axios';
import { Cookies } from 'react-cookie';

axios.defaults.baseURL = process.env.REACT_APP_SERVICE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const logoutUrl = `${process.env.REACT_APP_SERVICE_URL}/account/logout`;
        console.log(error.message);
        if (error.message == 'Request failed with status code 401') {
            window.location.replace(logoutUrl);
        }
        return Promise.reject(error);
    }
);

const setSession = (user) => {
    let cookies = new Cookies();
    if (user) cookies.set('user', JSON.stringify(user), { path: '/' });
    else cookies.remove('user', { path: '/' });
};

export const loginUser = (data, Type) =>
    axios.post('/auth/signIn', data).then((res) => {
        if (res.status === 200) {
            if (res.data.token) {
                localStorage.setItem('token', 'Bearer ' + res.data.token);
                localStorage.setItem('userEmail', res.data.email);
                localStorage.setItem('userName', res.data.username);
                localStorage.setItem('userRoles', res.data.roles);
                localStorage.setItem('hsptId', res.data.hsptId);
                localStorage.setItem('userId', res.data.id);
                localStorage.setItem('userLoginCntCheck', '0');
                setSession(res.data);
                if (Type == 'WEB') {
                    window.location.replace('/dashboard');
                } else if (Type == 'MOBILE') {
                    window.location.replace('/responseAnlyList');
                }
            } else {
                if (typeof res.data === 'string') {
                    const splitting = res.data.split('/');
                    const status = splitting[0];
                    const message = splitting[1].split('[')[0];
                    const count = splitting[1].split('[')[1];

                    return { status: Number(status), data: message, count };
                }
            }
        } else {
            return { status: res.status, data: res.data };
        }
    });

export const logoutUser = () => {
    try {
        setSession(null);
        localStorage.clear();
        window.location.reload();
    } catch (error) {}
};

export const checkPassword = (data) =>
    axios.post('/user/checkPassword', data).then((res) => {
        if (res.status == 200) {
            return res.data;
        }
    });

export const changePassword = (data) =>
    axios.post('/user/changePassword', data).then((res) => {
        if (res.status == 200) {
            return res.data;
        }
    });

export const resetErrLoginCount = (data) =>
    axios.get('/user/resetUserWrongPwCnt', { params: data }).then((res) => {
        if (res.status == 200) {
            return res.data;
        }
    });

export const getHospitalList = (data) =>
    axios
        .get('/hospital/getHospitalList', { params: data })
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const saveHospital = (data) =>
    axios
        .post('/hospital/saveHospital', data)
        .then((res) => {
            if (res.status == 200) {
                /*let returnData = res.data;
            return returnData;*/
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const deleteHospital = (data) =>
    axios.delete('/hospital/deleteHospital', { data }).then((res) => {
        if (res.status === 200) {
            window.location.reload();
        }
    });

export const getPatient = (data) =>
    axios
        .get('/patient/getPatient', { params: data })
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getPatientList = (data) =>
    axios
        .get('/patient/getPatientList', { params: data })
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const saveAnalysis = (data) =>
    axios
        .post('/analysis/saveAnalysis', data)
        .then((res) => {
            if (res.status == 200) {
                return res.data.anlyId;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getAnalysis = (data) =>
    axios
        .get('/analysis/getAnalysis', { params: data })
        .then((res) => {
            if (res.status == 200) {
                return res;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getAnalysisMappedInfo = (data) =>
    axios
        .get('/analysis/getAnalysisMappedInfo', { params: data })
        .then((res) => {
            if (res.status == 200) {
                return res;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getAnalysisSavedInfo = (data) =>
    axios
        .get('/analysis/getAnalysisSavedInfo', { params: data })
        .then((res) => {
            if (res.status == 200) {
                return res;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getExistAnalysisResultData = (data) =>
    axios.get('/analysis/getExistAnalysisResultData', { params: data }).then((res) => {
        if (res.status == 200) {
            return res.data;
        }
    });

export const getAnalysisResult = (data) =>
    axios.get('/analysis/getAnalysisResult', { params: data }).then((res) => {
        if (res.status == 200) {
            return res;
        }
    });

export const getAnalysisMappingData = (data) =>
    axios
        .get('/analysis/getAnalysisMappingData', { params: data, responseType: 'arraybuffer' })
        .then((res) => {
            if (res.status == 200) {
                const image = btoa(
                    new Uint8Array(res.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ''
                    )
                );
                return `data:${res.headers['content-type'].toLowerCase()};base64,${image}`;
            } else {
                return '';
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getAnlyRsltDataImg = (data) =>
    axios
        .get('/analysis/getAnlyRsltDataImg', { params: data, responseType: 'arraybuffer' })
        .then((res) => {
            if (res.status == 200) {
                const image = btoa(
                    new Uint8Array(res.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ''
                    )
                );
                return `data:${res.headers['content-type'].toLowerCase()};base64,${image}`;
            } else {
                return '';
            }
        });

export const getAnalysisMapping = (data) =>
    axios
        .post('/analysis/getAnalysisMapping', data)
        .then((res) => {
            if (res.status == 200) {
                return res;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getAnalysisList = (data) =>
    axios
        .get('/analysis/getAnalysisList', { params: data })
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const saveAnalysisData = (data) =>
    axios
        .post('/analysis/saveAnalysisData', data)
        .then((res) => {
            if (res.status == 200) {
                return res;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getAnalysisData = (data) =>
    axios
        .get('/analysis/getAnalysisData', { params: data, responseType: 'arraybuffer' })
        .then((res) => {
            if (res.status == 200) {
                const image = btoa(
                    new Uint8Array(res.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ''
                    )
                );
                if (image != null && image != '') {
                    return `data:${res.headers['content-type'].toLowerCase()};base64,${image}`;
                } else {
                    return null;
                }
            } else {
                return '';
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const saveAnalysisMapping = (data) =>
    axios
        .post('/analysis/saveAnalysisMapping', data)
        .then((res) => {
            if (res.status == 200) {
                return res;
                /*let returnData = res.data;
            return returnData;*/
            }
            return res;
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const saveAnalysisResult = (data) =>
    axios
        .post('/analysis/saveAnalysisResult', data)
        .then((res) => {
            if (res.status == 200) {
                return res.data;
                /*let returnData = res.data;
            return returnData;*/
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const saveAnalysisResultDetail = (data) =>
    axios.post('/analysis/saveAnalysisResultDetail', data).then((res) => {
        if (res.status == 200) {
            return res;
        }
    });

export const checkAnalysisData = (data) =>
    axios
        .post('/analysis/checkAnalysisData', data)
        .then((res) => {
            if (res.status == 200) {
                return res;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const saveAnalysisResultAndRcmnProduct = (data) =>
    axios.post('/analysis/saveAnalysisResultAndRcmnProduct', data).then((res) => {
        if (res.status == 200) {
            return res;
        }
    });

export const getDentistList = (data) =>
    axios.get('/user/getDentistList', { params: data }).then((res) => {
        if (res.status == 200) {
            return res.data;
        }
    });

export const savePatient = (data) =>
    axios
        .post('/patient/savePatient', data)
        .then((res) => {
            if (res.status == 200) {
                /*let returnData = res.data;
            return returnData;*/
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const deletePatient = (data) =>
    axios.delete('/patient/deletePatient', { data }).then((res) => {
        if (res.status === 200) {
            window.location.reload();
        }
    });

export const getCheckPatientEmailDuplication = (data) =>
    axios
        .get('/patient/checkPatientEmailDuplication', { params: { ptEmail: data } })
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getCheckPatientContactDuplication = (data) =>
    axios
        .get('/patient/checkPatientContactDuplication', { params: { ptContact: data } })
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getCommonCodeList = (data) =>
    axios
        .get('/commonCode/getCommonCodeList', { params: data })
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getCheckCommonCodeDuplication = (data) =>
    axios
        .get('/commonCode/checkCommonCodeDuplication', { params: { cd: data } })
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const saveCommonCode = (data) =>
    axios
        .post('/commonCode/saveCommonCode', data)
        .then((res) => {
            if (res.status == 200) {
                /*let returnData = res.data;
            return returnData;*/
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getCommonCodeDetailList = (data) =>
    axios
        .get('/commonCode/getCommonCodeDetailList', { params: data })
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getProductList = (data) =>
    axios
        .get('/product/getProductList', { params: data })
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getProductImage = (data) =>
    axios
        .get('./product/getProductImage', { params: data, responseType: 'arraybuffer' })
        .then((res) => {
            if (res.status == 200) {
                const image = btoa(
                    new Uint8Array(res.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ''
                    )
                );
                return `data:${res.headers['content-type'].toLowerCase()};base64,${image}`;
            } else {
                return '';
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const saveProduct = (data) =>
    axios
        .post('/product/saveProduct', data)
        .then((res) => {
            if (res.status == 200) {
                return res.status;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const deleteProduct = (data) =>
    axios.delete('/product/deleteProduct', { data }).then((res) => {
        if (res.status === 200) {
            window.location.reload();
        }
    });

export const getUserList = (data) =>
    axios
        .get('/user/getUserList', { params: data })
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const deleteUser = (data) =>
    axios.delete('/user/deleteUser', { data }).then((res) => {
        if (res.status === 200) {
            window.location.reload();
        }
    });

export const saveUser = (data) =>
    axios
        .post('/user/saveUser', data)
        .then((res) => {
            if (res.status == 200) {
                /*let returnData = res.data;
            return returnData;*/
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getAuthorityList = (data) =>
    axios
        .get('/authority/getAuthorityList', { params: data })
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const saveAuthority = (data) =>
    axios
        .post('/authority/saveAuthority', data)
        .then((res) => {
            if (res.status == 200) {
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getUnderUprnCdList = (data) =>
    axios
        .get('/commonCode/getUnderUprnCdList', { params: data })
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getMenuByRole = (data) =>
    axios.get('/commonCode/role/menu', { params: data }).then((res) => {
        if (res.status == 200) return res.data;
    });

export const saveMobileAnalysis = (data) =>
    axios.post('/analysis/saveMobileAnalysis', data).then((res) => {
        if (res.status == 200) {
            return res;
        }
    });

export const getMobileAnalysisList = (data) =>
    axios.get('/analysis/getMobileAnalysisList', { params: data }).then((res) => {
        if (res.status == 200) {
            return res;
        }
    });

export const getMobileAnalysisDataOne = (data) =>
    axios
        .get('/analysis/getMobileAnalysisDataOne', { params: data, responseType: 'arraybuffer' })
        .then((res) => {
            if (res.status == 200) {
                const image = btoa(
                    new Uint8Array(res.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ''
                    )
                );
                return `data:${res.headers['content-type'].toLowerCase()};base64,${image}`;
            } else {
                return '';
            }
        });

export const getMobileAnlyDataId = (data) =>
    axios.get('/analysis/getMobileAnlyDataId', { params: data }).then((res) => {
        if (res.status == 200) {
            return res;
        }
    });

export const getMobileAnalysisData = (data) =>
    axios
        .get('/analysis/getMobileAnalysisData', { params: data, responseType: 'arraybuffer' })
        .then((res) => {
            if (res.status == 200) {
                const image = btoa(
                    new Uint8Array(res.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ''
                    )
                );
                return `data:${res.headers['content-type'].toLowerCase()};base64,${image}`;
            } else {
                return '';
            }
        });

export const saveMobileAnalysisResult = (data) =>
    axios.post('/analysis/saveMobileAnalysisResult', data).then((res) => {
        if (res.status == 200) {
            return res;
        }
    });

export const getMobileAnalysisResult = (data) =>
    axios.get('/analysis/getMobileAnalysisResult', { params: data }).then((res) => {
        if (res.status == 200) {
            return res;
        }
    });

export const getMobileRcmnProduct = (data) =>
    axios.get('/product/getMobileRcmnProduct', { params: data }).then((res) => {
        if (res.status == 200) {
            return res;
        }
    });

export const getPreviewAnlyList = (data) =>
    axios.get('/analysis/getPreviewAnlyList', { params: data }).then((res) => {
        if (res.status == 200) {
            return res.data;
        }
    });

export const getCountAnly = (data) =>
    axios.get('/analysis/getCountAnly', { params: data }).then((res) => {
        if (res.status == 200) return res.data;
    });

export const getCountPatient = (data) =>
    axios.get('/patient/getCountPatient', { params: data }).then((res) => {
        if (res.status == 200) return res.data;
    });

export const getCountHospital = (data) =>
    axios.get('/hospital/getCountHospital', { params: data }).then((res) => {
        if (res.status == 200) return res.data;
    });

export const getAnlyDataCount = (data) =>
    axios.get('/analysis/getAnlyDataCount', { params: data }).then((res) => {
        if (res.status == 200) return res.data;
    });

export const getRcmnProduct = (data) =>
    axios.get('/analysis/getRcmnProduct', { params: data }).then((res) => {
        if (res.status == 200) return res;
    });

export const saveAnalysisResultDetailAiNum = (data) =>
    axios.get('/analysis/saveAnalysisResultDetailAiNum', { params: data }).then((res) => {
        if (res.status == 200) {
            return res;
        }
    });

export const getCheckUserEmailDuplication = (data) =>
    axios
        .get('/user/getCheckUserEmailDuplication', { params: data })
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const deleteAnalysis = (data) =>
    axios.get('/analysis/deleteAnalysis', { params: data }).then((res) => {
        if (res.status == 200) window.location.reload();
    });

export const deleteUnmappingData = (data) =>
    axios.post('/analysis/deleteUnmappingData', data).then((res) => {
        if (res.status == 200) {
            return res.data;
        }
    });

export const completeRegistAnalysis = (data) =>
    axios.get('/analysis/completeRegistAnalysis', { params: data }).then((res) => {
        // if (res.status == 200) {
        //     return res.data;
        // }

        return res;
    });

export const downloadAnlyReport = (data) =>
    axios
        .get('/analysis/downloadAnlyReport', { params: data, responseType: 'arraybuffer' })
        .then((res) => {
            if (res.status == 200) {
                var file = new Blob([res.data], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                return fileURL;
            } else {
                return '';
            }
        })
        .catch(function (error) {
            return Promise.reject(error);
        });

export const getReportList = (data) =>
    axios.get('/analysis/getReportList', { params: data }).then((res) => {
        if (res.status == 200) {
            return res.data;
        }
    });

export const saveAiNum = (data) =>
    axios.get('/analysis/saveAiNum', { params: data }).then((res) => {
        if (res.status == 200) {
            return res.data;
        }
    });

export const getReportLink = () =>
    axios.get('/analysis/getReportLink').then((res) => {
        if (res.status == 200) {
            return res.data;
        }
    });

const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then((response) => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then((json) => {
            return json;
        })
        .catch((error) => {
            throw error;
        });
};

export { fetchJSON };

export const request = (method = 'post', url, data = null) => {
    if (!!url) {
        axios({
            method: method,
            url: url,
            data: data,
        })
            .then((res) => res.data)
            .catch((err) => {
                console.log('!! request err :', err);
                return null;
            });
    } else {
        console.log("!! There's no url");
    }
};

const setReqInfo = ({ method = 'post', url, params = null, responseType, isNonBaseUrl }) => {
    return {
        method: method,
        url: url,
        params: method === 'get' ? params : null,
        data: method !== 'get' ? params : null,
        responseType: responseType || 'json',
        baseURL: !!isNonBaseUrl ? '' : process.env.REACT_APP_SERVICE_URL,
    };
};

export const fileDownload = async (options) => {
    try {
        const params = setReqInfo({ ...options, responseType: 'arraybuffer' });
        const res = await axios(params);

        console.log('!!! res', res);
        const file = new Blob([res.data], { type: res.headers['content-type'] });
        const fileURL = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = fileURL;
        a.download = options.fileName || '메뉴얼';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(fileURL);

        return {
            data: fileURL,
            status: res.status,
        };
    } catch (err) {
        console.log('!! file download err :', err);
        return { data: null, status: err.response.status };
    }
};
